<script>
export default {
  mounted () {
    this.$store
      .dispatch('logout')
      .then(() => this.$router.push('/'))
      .catch((err) => console.log(err))
  }
}
</script>
